import React from 'react';
import { Component } from "react";

import { Redirect } from "react-router-dom";
import { Button, AppBar, Toolbar, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//     root: {
//       flexGrow: 1,
//     },
//     menuButton: {
//       marginRight: theme.spacing(2),
//     },
//     title: {
//       flexGrow: 1,
//     },
// }));

const GrowingTitle = styled(Typography)({
    flexGrow: 1,
});

const UserName = styled(Typography)({
    marginRight: '20px'
});

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.render = this.render.bind(this);
        this.handleSignOut = this.handleSignOut.bind(this);
    }

    handleSignOut() {
        this.props.handleSignOut();
        this.setState(this.state)
    }

    render() {
        if (this.props.user.isSignedIn) {
            return (
                <AppBar position="fixed">
                    <Toolbar>
                        <GrowingTitle variant="h6" color="inherit">Розсилка розрахункових листiв</GrowingTitle>
                        <UserName variant="h6" color="inherit">{this.props.user.userName}</UserName>
                        <Button variant="contained" color="primary" onClick={this.handleSignOut}>Вийти</Button>
                    </Toolbar>
                </AppBar>
            )
        } else {
            return <Redirect to='/login' />
        }
    }
}


export default UserInfo;