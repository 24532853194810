import React from 'react';
import { Component } from "react";

import Button from '@material-ui/core/Button';
import { Grid, Snackbar, TextField } from '@material-ui/core';
import { Redirect } from "react-router-dom";


class LoginFrom extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {snackbar: undefined};
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit() {
        const data = new URLSearchParams();
        data.append("login", this.state.login)
        data.append("password", this.state.password)

        fetch('/api/authenticate', {
            method: 'POST',
            body: data
        }).then((response) => {
            if (response.status !== 200) {
                const snackbar = {
                    message: "Авторизація неуспішна: Введено невірний логін або пароль"
                };
                this.setState({snackbar})
            }

            if (this.props.onSubmit !== undefined) {
                this.props.onSubmit();
            }
        })
    }

    render() {

        if (this.props.isSignedIn) {
            return <Redirect to='/' />
        }

        return (
            <>
            <form onSubmit = {this.handleSubmit}>
                <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >

                    <Grid item>
                        <TextField label="Логін" name="login" onChange={this.handleInputChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField label="Пароль" type="password" name="password" onChange={this.handleInputChange}></TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={this.handleSubmit}>Увійти</Button>
                    </Grid>
                </Grid>

            </form>
            <Snackbar
                message = { this.state.snackbar && this.state.snackbar.message }
                visible = { true }
                onRequestClose = { () => { return null } }
                open = { this.state.snackbar != undefined }
                autoHideDuration = {3000}
                onClose = {() => this.setState({snackbar: undefined})}
            />
            </>
        );
    }
}

export default LoginFrom;