import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Grid, Snackbar } from '@material-ui/core';
// TODO: switch to BrowserRouter aftering figuring out files serve
import { HashRouter as Router, Route, Link } from "react-router-dom";


import LoginForm from './components/login-form';
import UserInfo from './components/user-info';
import Upload from './components/upload';


// let Upload = () => {
//   return <div>Upload</div>
// }

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {
        isSignedIn: false,
        userName: undefined
      },
    };
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.signOut = this.signOut.bind(this);

    this.fetchUserInfo();
  }

  fetchUserInfo() {
    fetch('/api/user').then((response) => {
        if (response.status === 200) {
            this.setState({auth: {isSignedIn: true}});
            return response.json();
        }
    }).then((json) => {
      if (json) {
        this.setState((state) => {
          return state.auth.userName = json.name;
        });
      }
    });
  }

  signOut() {
    this.signedIn = false;
    this.userName = undefined;
    fetch('/api/logout', {method: 'POST'});
    this.setState({auth: {
      isSignedIn: false,
      userName: undefined
    }})
  }

  render() {
    return (
      <Router>
        <UserInfo user={this.state.auth} handleSignOut={ this.signOut } />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >

        <Route path="/login" render = { props => <LoginForm {...props} onSubmit = { this.fetchUserInfo } isSignedIn = {this.state.auth.isSignedIn} /> } />
        <Route path="/done" render = { props =>
          <>
            <div>Done</div>
            <Snackbar
              message = {"Розрахункові листи надіслані"}
              visible = { true }
              onRequestClose = { () => { return null } }
            />
            <Upload {...this.props} />
          </>
        } />
        <Route path="/" component = { Upload } exact />

        </Grid>
      </Router>
    );
  }
}


ReactDOM.render(<App />, document.querySelector('#root'));

export default App;
