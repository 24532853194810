import React from 'react';
import { Component } from "react";

import { Redirect } from "react-router-dom";
import { Button, LinearProgress, Paper, Chip, Avatar } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/Done';


class Upload extends Component {
    constructor(props) {
        super(props);
        this.render = this.render.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseChip = this.handleCloseChip.bind(this);
        this.fileInput = React.createRef();
        this.state = {
            uploaded: false,
            uploading: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData();
        formData.append('payslips', this.fileInput.current.files[0]);
        this.setState({uploaded: false, uploading: true});
        fetch('/api/upload', {
            method: 'POST',
            body: formData
        }).then(success => {
            this.setState({uploaded: true, uploading: false});
        }).catch(error => {
            this.setState({uploading: false});
            console.log(error);
        });
    }

    handleCloseChip() {
        this.setState({uploaded: false});
    }

    render() {
        return (
            <>
            <form onSubmit={this.handleSubmit}>
                <input
                    accept=".json,application/json"
                    type="file"
                    name="payslips"
                    id="payslips-file"
                    style={{ display: 'none' }}
                    ref={this.fileInput}
                />
                <label htmlFor="payslips-file">
                    <Button variant="contained" color="primary" component="span" size="large" style={ { marginRight: '2px' } } >
                        <CloudUploadIcon style={ { marginRight: '10px' } } />
                        Вибрати файл для імпорту
                    </Button>
                </label>

                <Button type="submit" variant="contained" color="primary" size="large">Розіслати</Button>
            </form>
            {this.state.uploading && <LinearProgress style={ {width: '444px', marginTop: '10px'} } color="secondary" />}
            {this.state.uploaded &&
                <Chip
                    avatar={<DoneIcon />}
                    label="Листи надіслано"
                    clickable
                    color="secondary"
                    style={ {marginTop: '10px'} }
                    onClick={this.handleCloseChip}
              />
            }
            </>
        )
    }
}


export default Upload;